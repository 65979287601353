<template>
	<div>
		<div class="appletIndex">
			<div class="ai_div" @click="routeClick(0)">
				<div>
					<div class="ai_di_icon">
						<img class="ai_di_ic_img" :src="$route.path=='/' ? require('../../assets/img/icon/home2.png') : require('../../assets/img/icon/home1.png')" />
					</div>
					<div class="ai_di_text">{{$t('home.bottom.text1')}}</div>
				</div>
			</div>
			
			<div class="ai_div" @click="routeClick(1)">
				<div>
					<div class="ai_di_icon">
						<img class="ai_di_ic_img" :src="$route.path=='/myOrder' ? require('../../assets/img/icon/order2.png') : require('../../assets/img/icon/order1.png')" />
					</div>
					<div class="ai_di_text">{{$t('home.bottom.text3')}}</div>
				</div>
			</div>
			
			<div class="ai_div" @click="routeClick(2)">
				<div>
					<div class="ai_di_icon">
						<img class="ai_di_ic_img" :src="$route.path=='/pointsMall' ? require('../../assets/img/icon/order2.png') : require('../../assets/img/icon/order1.png')" />
					</div>
					<div class="ai_di_text">{{$t('home.bottom.text4')}}</div>
				</div>
			</div>
			
			<div class="ai_div" @click="routeClick(3)">
				<div>
					<div class="ai_di_icon">
						<img class="ai_di_ic_img" :src="$route.path=='/personal' ? require('../../assets/img/icon/personal2.png') : require('../../assets/img/icon/personal1.png')" />
					</div>
					<div class="ai_di_text">{{$t('home.bottom.text2')}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				
			}
		},
		
		created() {
			// console.log(this.$route)
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			//----------------路由跳转---------------------------
			routeClick:function(e){
				if(e==0){
					if(this.$route.path!=='/'){
						this.$router.push({
							path: '/'
						})
					}
				}else if(e==1){
					if(this.$route.path!=='/myOrder'){
						this.$router.push({
							path: '/myOrder'
						})
					}
				}else if(e==2){
					if(this.$route.path!=='/pointsMall'){
						this.$router.push({
							path: '/pointsMall'
						})
					}
				}else{
					if(this.$route.path!=='/personal'){
						this.$router.push({
							path: '/personal'
						})
					}
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.appletIndex{
		position: fixed;
		bottom: 0;
		padding: 20px 0;
		background: #fff;
		box-shadow: 1px 4px 18px 0px rgb(128 128 128 / 20%);
		width: 100%;
		display: flex;
		z-index: 9999;
		
		.ai_div{
			width: 24%;
			display: flex;
			align-items: center;
			justify-content: center;
			
			.ai_di_icon{
				display: flex;
				align-items: center;
				justify-content: center;
				
				.ai_di_ic_img{
					width: 20px;
					height: 20px;
				}
			}
			
			.ai_di_text{
				display: flex;
				align-items: center;
				justify-content: center;
				color: #707070;
			}
		}
	}
</style>