<template>
	<div>
		<div style="display: flex;align-items: center;justify-content: center;height: calc(100vh - 100px);">
			<el-empty description="敬请期待~"></el-empty>
		</div>
		<applet-bottom :windowWidth="windowWidth" :windowHeight="windowHeight" v-if="windowWidth<=1199"></applet-bottom>
	</div>
</template>

<script>
	import appletBottom from '@/components/bottom/appletIndex.vue';
	export default {
		components: {
			appletBottom
		},
		data(){
			return {
				// 浏览器窗口宽度高度
				windowWidth: 0,
				windowHeight: 0,
			}
		},
		
		created() {
			let that = this;
			that.windowWidth = document.body.clientWidth;
			that.windowHeight = document.body.clientHeight;
			window.onresize = () => {
				return (() => {
					this.windowWidth = document.body.clientWidth;
					this.windowHeight = document.body.clientHeight;
				})();
			};
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
</style>